<template>
  <div>
    <el-dialog :close-on-click-modal="false" destroy-on-close title="打印设置" v-model="dialogFormVisibleManager" class="button_bg" width="810px">
      <el-scrollbar :height="contentStyleObj">
        <el-form ref="dataForm" label-position="right" label-width="110px">
        <el-form-item label="所属期:" v-if="datePeriod">
            <div>
              <el-date-picker
                v-model="startPeriod"
                type="month"
                placeholder="选择月"
                size="small"
                value-format="YYYYMM">
              </el-date-picker> --
              <el-date-picker
                v-model="endPeriod"
                type="month"
                placeholder="选择月"
                size="small"
                value-format="YYYYMM">
              </el-date-picker>
            </div>
        </el-form-item>
        <el-form-item label="是否打印封面:">
          <el-switch v-model="temp.fm" :active-value="1" :inactive-value="0" /> 
        </el-form-item>
        <el-form-item  label="封面类型:" v-if="temp.fm == 1">
          <div>
            <el-radio-group v-model="temp.faceType" @change="faceTypeChange(temp.faceType)">
              <el-radio :label="1">横版</el-radio>
              <el-radio :label="2">竖版</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="纸张类型:" size="small">
            <div>
              <el-radio-group v-model="temp.paperType">
                <el-radio label="A4-S1" border>A4纸-1</el-radio>
                <el-radio label="A4-S" border>A4纸-2</el-radio>
                <el-radio label="A4-S3" border>A4纸-3</el-radio>
                <el-radio label="A4-H" border>A4纸-横</el-radio>
                <el-radio label="A5-H" border>凭证纸</el-radio>
              </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item v-if="temp.paperType == 'A5-H'" label="快捷选择:">
            <div>
              <el-radio-group size="small" @change="kjChange" v-model="kj">
                <el-radio :label="1" border>凭证纸(竖)</el-radio>
                <el-radio :label="2" border>针式打印机</el-radio>
                <el-radio :label="3" border>凭证纸(横)</el-radio>
                <el-radio :label="4" border>默认</el-radio>
              </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item label="选项:">
            <div>
              <el-radio-group v-model="temp.optionType">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">外币</el-radio>
                <el-radio label="2">数量金额</el-radio>
              </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item label="字体：" class="item_row">
          <el-radio-group v-model="temp.ttf">
            <el-radio :label="1">宋体</el-radio>
            <el-radio :label="2">微软雅黑</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="薪酬签名栏：" class="item_row">
          <el-radio-group v-model="temp.signLine">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="辅助虚线：" class="item_row" v-if="temp.paperType == 'A4-S1' || temp.paperType == 'A4-S' || temp.paperType == 'A4-S3'">
            <el-radio-group v-model="temp.assistDottedLine">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        <el-form-item label="显示条数：" class="item_row">
          <el-input v-model.number="temp.num" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>

        <el-form-item label="字体大小" class="item_row">
          <el-input  type="number" v-model.number="temp.ttfSize" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>
        
        <el-form-item label="左偏移(mm)：" class="item_row">
          <el-input  type="number" v-model.number="temp.left" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="右偏移(mm)：" class="item_row">
          <el-input  type="number" v-model.number="temp.right" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>

        <div v-if="temp.paperType == 'A5-H'">
          <el-form-item label="上偏移(mm)：" class="item_row">
            <el-input  type="number" v-model.number="temp.top" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="下偏移(mm)：" class="item_row">
            <el-input  type="number" v-model.number="temp.bottom" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="宽度(mm)：" class="item_row">
            <el-input  type="number" v-model.number="temp.width" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="高度(mm)：" class="item_row">
            <el-input  type="number" v-model.number="temp.height" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
        </div>
        <div>
           <el-form-item label="附件：" class="item_row">
            <el-checkbox
              style="margin-right:15px"
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <el-checkbox-group v-model="fjCheck" @change="handleCheckedCitiesChange">
              <!-- <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox> -->
              <div>  
                <el-checkbox v-for="(city, index) in cities.slice(0, 4)" :key="city" :label="city">  
                  {{ city }}  
                </el-checkbox>  
              </div>
              <div>  
                <el-checkbox v-for="(city, index) in cities.slice(4, 9)" :key="city" :label="city">  
                  {{ city }}  
                </el-checkbox>  
              </div>
              <div>  
                <el-checkbox v-for="(city, index) in cities.slice(9, 13)" :key="city" :label="city">  
                  {{ city }}  
                </el-checkbox>  
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="选项：" class="item_row">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="屏蔽参考信息"></el-checkbox>
              <el-checkbox label="屏蔽业务日期"></el-checkbox>
              <el-checkbox label="屏蔽序号"></el-checkbox>
              <!-- <el-checkbox label="屏蔽制作时间"></el-checkbox> -->
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
      </el-scrollbar>
    
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisibleManager = false" size="small">取消</el-button>
          <el-button type="primary" @click="print()" size="small" :loading="btnLoading">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { printSetOne,accountBook } from "@/api/printSet";
export default {
  props: {
    name: {
      type: String,
    },
    listQuery1: {
      type: Object,
      default:{}
    },
    listIds:{
      type:Array
    },
    datePeriod:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      checkList: ['屏蔽参考信息'],
      fjCheck:[],
      cities:['进项','销项','发票清单','费用报销单','银行','薪酬','资产','出入库','上传附件','上月电子税局完税证明','本月电子税局完税证明','上月个税完税证明','本月个税完税证明'],
      checkAll:false,
      isIndeterminate: false,
      kj:1,
      temp: {
        paperType: "A4-S",
        height: 0,//pdf尺寸
        width: 0,//pdf尺寸
        num: 0,//生成一页条数
        left: 0,//pdf左偏移
        ttfSize: 0,
        top: 0,//偏移
        right: 0,//偏移
        bottom:0,//偏移
        ttf:1,
        optionType:"0",
        fj:false,
        assistDottedLine:1,
        fm:0,
        faceType:1,
      },
      dialogFormVisibleManager: false,
      comIds:[],
      startPeriod:'',
      endPeriod:'',
      btnLoading:false,
      signLine:0,
      contentStyleObj:{}
    };
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(300)
  },
  methods: {
    faceTypeChange(val){
      localStorage.setItem('faceType',val)
    },
    init(comIds){
      if(comIds){
        this.comIds = comIds
      }
      // this.initTemp()
      this.dialogFormVisibleManager = true
      printSetOne({type:'pdf', bookName:this.name}).then(res=>{
        if(res.data.msg == 'success'){
          this.temp = res.data.data.info?Object.assign({},res.data.data.info):this.temp
          console.log(this.temp);
          this.temp.faceType = localStorage.getItem("faceType") ? localStorage.getItem("faceType")*1 : 1
          this.temp.type = "pdf"
          this.temp.optionType = '0'
          this.temp.fj = false
          this.temp.assistDottedLine = 1
          this.checkList = res.data.data.info.option?res.data.data.info.option.split('-'):['屏蔽参考信息']
          this.temp.signLine = 0
          this.fjCheck = res.data.data.info.fjSet?res.data.data.info.fjSet.split(','):[]
          let checkedCount = this.fjCheck.length;
          this.checkAll = checkedCount === this.cities.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        }
      })
    },
    kjChange(){
      if(this.kj == 1){//凭证纸(竖) W: 595.28, H: 419.53
        this.temp.width = 298
        this.temp.height = 210
        this.temp.left = 10
        this.temp.right = 10
        this.temp.top = 10
        this.temp.bottom = 10
        this.temp.num = 5
        this.temp.ttfSize = 8
      }else if(this.kj == 2){//针式打印机
        this.temp.width = 354
        this.temp.height = 249
        this.temp.left = 10
        this.temp.right = 10
        this.temp.top = 10
        this.temp.bottom = 10
        this.temp.num = 5
        this.temp.ttfSize = 8
      }else if(this.kj == 3){//凭证纸横向
        this.temp.width = 298
        this.temp.height = 210
        this.temp.left = 20
        this.temp.right = 20
        this.temp.top = 20
        this.temp.bottom = 20
        this.temp.num = 5
        this.temp.ttfSize = 8
      }else if(this.kj == 4){
        this.temp.width = 340
        this.temp.height = 198
        this.temp.left = 10
        this.temp.right = 10
        this.temp.top = 10
        this.temp.bottom = 10
        this.temp.num = 5
        this.temp.ttfSize = 8
      }
    },
    // 打印
    print(){
      if(this.temp.num > 8){
        this.$qzfMessage('条数最大为8！',1);
        return
      } 
      if(!this.temp.num){
        this.$qzfMessage('打印条数不能为0！',1);
        return
      }
      if(this.temp.ttfSize > 12){
        this.$qzfMessage('字体最大为12！',1);
        return
      }
      if(this.temp.left > 50){
        this.$qzfMessage('左偏移最大为50！',1);
        return
      }
      if(this.temp.right > 25){
        this.$qzfMessage('右偏移最大为25！',1);
        return
      }

      if(this.temp.paperType == 'A5-H'){
        if(this.temp.width<200){
          this.$message.error('宽度不能小于200');
          return
        }
        if(this.temp.height<100){
          this.$message.error('高度不能小于100');
          return
        }

      }
      let params = Object.assign({}, this.temp)
      if(this.comIds.length != 0){
        params.comIds = this.comIds
      }else{
        params.comIds = [ this.$store.getters['user/comInfo'].comId*1]
        params.ids = this.listIds
      }
      if(params.paperType == 'A4-H'){
        params.width = 297
        params.height = 210
      }
      params.bookNames = [this.name]
      params.option = this.checkList.join('-')
      params.fj = this.fjCheck
      params.query = this.listQuery1
      params.query.name = ''
      // params.startPeriod = this.startPeriod
      // params.endPeriod = this.endPeriod
      params.query.beginTime = this.startPeriod
      params.query.endTime = this.endPeriod
      params.left = params.left*1
      params.right = params.right*1
      params.ttfSize = params.ttfSize*1
      if(this.listQuery1.endTime < this.listQuery1.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
        return
      }
      if(this.listIds.length == 0 && this.comIds.length == 0) {
        this.$message.error('请至少选择一张要打印的凭证');
        return
      }
      if(params.comIds.length > 1){
        params.zip = 1 
      } else {
        params.zip = 0
      }
      this.btnLoading = true
    
      accountBook(params).then(res=>{
        this.btnLoading = false
        this.dialogFormVisibleManager = false
        if(res.data.msg == 'success'){
          if (res.data.data.msg) {
           this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
        }
      })
    },
    handleCheckAllChange(val) {
      this.fjCheck = val? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }
  }
};
</script>
<style lang="scss" scoped>

</style>


